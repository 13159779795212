export const ILLUMINATIONS_CATEGORIES = [
  {
    categories: 'team-lab', // カテゴリー名
    name: 'みずほPayPayドーム福岡', // エリア名
    // detail: '<p>ふつうのSNS映えでは満足できない人たちの心を満たす、フォトジェニックな空間。飛び交うシュールなうんこやカラフルに光り輝くうんこなど、さまざまなうんこを撮影してみましょう。</p>', // 説明
    img: 'illumination-building-1.jpg', // 画像名
    imgModal: 'illumination-building-1.jpg', // 画像名
    colorClass: 'floorbc-tr1', // シャドウカラー設定
    imgSlider: false,
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: 'BOSS E・ZO FUKUOKA', // エリア名
    // detail: '<p>地面に映し出されたうんこを踏んづける体感ゲームや、うんこ〜！と叫んで声量に応じたうんこを生み出すゲームなど、子供心をくすぐるゲームをご用意しました。大人の方も、童心に返って全力でお楽しみください。</p>', // 説明
    img: 'illumination-building-2.jpg', // 画像名
    imgModal: 'illumination-building-2-2.jpg', // 画像名
    imgSlider: false,
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: 'みずほPayPayドームとE・ZO', // エリア名
    // detail: '<p>うんこを撮影する、うんこと恋する、うんこにダイブする…。何度でも遊びたくなる、中毒性の高いクソゲーが楽しめます。高得点を出せた時の爽快感は快便以上！</p>', // 説明
    img: 'illumination-building-3.jpg', // 画像名
    imgModal: 'illumination-building-3-2.jpg', // 画像名
    imgSlider: false,
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: 'ホークスみんなのガーデン アニマルオブジェ', // エリア名
    // detail: '<p>「勝うん様」に参拝し、“うん” をつけよう！「勝うん様」はミュージアム入場券をお持ちでない方もお楽しみいただけます。野球観戦の前に立ち寄って、ホークスの勝利を祈願しましょう！</p><p><span class="attention">※</span>勝うん様はミュージアム入場券をお持ちでない方もご入場いただけるエリアです</p>', // 説明
    img: 'illumination-building-4.jpg', // 画像名
    imgModal: 'illumination-building-4-2.jpg', // 画像名
    imgSlider: false,
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: 'ホークスみんなのガーデン フォトスポット', // エリア名
    // detail: '<p>祈念を込めて黄金のうんこを撫でて、勝ちうん（運）をつけよう！<br />あなただけの「マイうんこ」に、ホークス勝利への熱い思いや祈りを込めたメッセージを記入してお供えすると、「勝うん様」に思いが届くかも・・?!！</p><p><span class="attention">※</span>撫でうんはミュージアム入場券をお持ちでない方もご入場いただけるエリアです</p>', // 説明
    img: 'illumination-building-5.jpg', // 画像名
    imgModal: 'illumination-building-5-2.jpg', // 画像名
    imgSlider: false,
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: 'E・ZO前', // エリア名
    // detail: '<p>祈念を込めて黄金のうんこを撫でて、勝ちうん（運）をつけよう！<br />あなただけの「マイうんこ」に、ホークス勝利への熱い思いや祈りを込めたメッセージを記入してお供えすると、「勝うん様」に思いが届くかも・・?!！</p><p><span class="attention">※</span>撫でうんはミュージアム入場券をお持ちでない方もご入場いただけるエリアです</p>', // 説明
    img: 'illumination-building-6.jpg', // 画像名
    imgModal: 'illumination-building-6-2.jpg', // 画像名
    imgSlider: false,
  },
];

export const ILLUMINATIONS_CATEGORIES2 = [
  {
    categories: 'team-lab', // カテゴリー名
    name: 'MARK IS 福岡ももち デッキイルミネーション', // エリア名
    // detail: '<p>みずほPayPayドーム開催のホームゲームにおいて福岡ソフトバンクホークスが勝利するたびに、選手のサインが書かれたうんこ型の色紙が勝利のうんこウォールに増えていきます。この壁がうんこまみれになりますように。</p>', // 説明
    img: 'illumination-building-8.jpg', // 画像名
    imgSlider: false,
    detail:
      '待ち合わせ場所にも使われる2階ももちステージのクリスマスツリーの森や、2階MARK ISデッキのクリスマスムード高まるイルミネーション、光の海をお楽しみください。',
  },
];

export const EVENT_BOX = [
  {
    colorClass: 'floorbc-tr1', // シャドウカラー設定
    img: 'illumination_event-1.jpg',
    imgSlider: false,
    ttl: 'BOSS E・ZO FUKUOKA',
    descDl: '2023.11.23～2024.1.8',
    subTtl: '2023 WINTER LIGHT FANTASY',
    link: '/news/hkt/2023111024289/',
    isExternalLink: false,
    desc:
      '素敵な冬を満喫できる「2023 WINTER LIGHT FANTASY“ウィンターライトファンタジー”」を11月23日（木・祝）～2024年1月8日（月・祝）の期間で開催します。ドームやE・ZOが光り輝くイルミネーションで彩られ、チームラボフォレストは冬景色に変化します。また、豪華景品が当たる“クリスマス大抽選会”も実施します。年末年始にも特別なイベントを開催。随時情報をお知らせしていきますので、公式サイトのチェックをお忘れなく！',
    linkText: '詳細はこちら',
  },
  {
    colorClass: 'floorbc-tr1', // シャドウカラー設定
    img: 'illumination_event-3.jpg',
    imgSlider: false,
    ttl: 'ヒルトン福岡シーホークのクリスマス',
    link: 'https://fukuokaseahawk.hiltonjapan.co.jp/event/christmas/',
    isExternalLink: true,
    desc:
      '今年で13回目を迎える「ヒルトン・クリスマス・トレイン」は、ヒルトンの企業責任戦略『トラベル・ウィズ・パーパス（Travel with Purpose）』の一環でもある地域社会への貢献活動の一つです。カラフルな列車や色とりどりの気球、輝く観覧車などのアイテムで再現された冬のヨーロッパの景色を50.3㎡の巨大ジオラマとしてホテルロビーに展示しております。また、ホテルレストランでは、様々なクリスマス限定メニューをご準備しております。',
    linkText: 'ヒルトン福岡シーホークWebサイト',
  },
];
